<template>
  <el-container>
    <el-table
        :data="tableData.filter(searchFilter)"
        style="width: 100%"
    >
      <el-table-column
          prop="id"
          label="id"
          sortable
          width="120">
      </el-table-column>
      <el-table-column
          prop="blog_id"
          label="文章id"
          sortable
          width="120">
        <template slot-scope="scope">
          <a :href="'/discuss/'+scope.row.blog_id" target="_blank">{{ scope.row.blog_id }}</a>
        </template>
      </el-table-column>
      <el-table-column
          prop="lineup_title"
          label="lineup标题"
          width="200"
      >
      </el-table-column>
      <el-table-column
          prop="side"
          label="选边"
          width="300"
          sortable
      >
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.side" @change="sideChange(scope.row, $event)">
            <el-radio-button :label="0">未分配</el-radio-button>
            <el-radio-button :label="1">进攻</el-radio-button>
            <el-radio-button :label="2">防守</el-radio-button>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          width="300"
      >
        <template slot="header" slot-scope="scope">
          <el-input
              :a="scope"
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"
          />
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
import axios from 'axios'

export default {
  name: "Lineup",
  data() {
    return {
      tableData: [],
      search: ''
    }
  },
  mounted() {
    this.getAllLineup()
  },
  methods: {
    getAllLineup() {
      axios({
        method: 'get',
        url: '/api/blogs/get_all_lineup',
      }).then(r => {
        if (r.data.code === 0) {
          this.tableData = r.data.list
        } else {
          this.$message.error('加载失败');
        }
      })
    },
    sideChange(row, side) {
      axios({
        method: 'post',
        url: '/api/blogs/edit_lineup_side',
        data: {
          id: row.id,
          side: side
        },
      }).then(r => {
        if (r.data.code === 0) {
          this.$message.success('修改成功');
        } else {
          this.$message.error('修改失败');
        }
      })
    },
    searchFilter(data) {
      if (data.id.toString().includes(this.search.toString())) {
        return true
      }
      if (data.blog_id.toString().includes(this.search.toString())) {
        return true
      }
      if (data.lineup_title.toString().includes(this.search.toString())) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>